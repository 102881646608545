import { createRouter, createWebHistory } from "vue-router";
import { useLoginStore } from "../store/loginStore";
import LoginPage from "../pages/login/loginPage.vue";
import Home from "../pages/home/homePage.vue";
import Profile from "../pages/profile/profilePage.vue";
import ProjectsListKanban from "../pages/products/projects/projectListPage.vue";
import EmailPage from "../pages/products/email/emailPage.vue";
import DashPage from "../pages/dash/handler/dashPage.vue";
import ViewsPage from "../pages/registration/views/viewsPage.vue";
import TestesLuanPage from "../pages/testes/AppTestes.vue";
import AccessGroupPage from "../pages/securityAndSettings/accessGroup/accessGroupPage.vue";
import { useApi } from "@/store/api-gridStore";

function guardianRoute(to, from, next) {
  if (useLoginStore().authenticated === false) {
    next("/login");
  } else {
    next();
  }
  screenTop;
}

const routes = [
  {
    path: "/login",
    name: "login",
    component: LoginPage,
  },

  {
    path: "/login/forgotpwd",
    name: "reset-password",
    component: () =>
      import("@/pages/login/forgotPasswordPage.vue"),
  },

  {
    path: "/login/forgotpwd/confirm",
    name: "confirm-reset-password",
    component: () =>
      import("@/pages/login/confirmResetPasswordPage.vue"),
  },

  {
    path: "/",
    name: "home",
    component: Home,
    meta: {
      breadcrumb: [{ name: "Home", icon: "home" }],
    },
    children: [
      {
        path: "profile/:id",
        name: "profile",
        component: Profile,
        meta: {
          breadcrumb: [
            { name: "Home", link: "/", icon: "home" },
            { name: "Perfil" },
          ],
        },
      },

      {
        path: "/tickets",
        name: "tickets",
        component: () => import("../pages/tickets/TicketPage.vue"),
        meta: {
          breadcrumb: [
            { name: "Home", link: "/", icon: "home" },
            { name: "Tickets" },
          ],
        },
        beforeEnter: guardianRoute,
      },

      //  Admin
      {
        path: "admin-user",
        name: "admin-user",
        component: () =>
          import("@/pages/securityAndSettings/users/usersPage.vue"),
        meta: {
          breadcrumb: [
            { name: "Home", link: "/", icon: "home" },
            { name: "Usuários" },
          ],
        },
        children: [
          {
            path: "",
            name: "table-user",
            component: () =>
              import("@/pages/securityAndSettings/users/tableUsers.vue"),
            meta: {
              breadcrumb: [
                { name: "Home", link: "/", icon: "home" },
                { name: "Usuários" },
              ],
            },
          },
          {
            path: ":id",
            name: "form-edit",
            component: () =>
              import("@/pages/securityAndSettings/users/formUser.vue"),
            meta: {
              breadcrumb: [
                { name: "Home", link: "/", icon: "home" },
                { name: "usuários" },
              ],
            },
          },
          {
            path: "create",
            name: "form-create",
            component: () =>
              import("@/pages/securityAndSettings/users/formUser.vue"),
            meta: {
              breadcrumb: [
                { name: "Home", link: "/", icon: "home" },
                { name: "usuários" },
              ],
            },
          },
        ],
      },
      {
        path: "admin-module",
        name: "admin-module",
        component: () =>
          import("@/pages/securityAndSettings/module/modulePage.vue"),
        meta: {
          breadcrumb: [
            { name: "Home", link: "/", icon: "home" },
            { name: "Módulos" },
          ],
        },
      },
      {
        path: "admin-config",
        name: "admin-config",
        component: () =>
          import(
            "@/pages/securityAndSettings/systemSettings/systemSettingsPage.vue"
          ),
        meta: {
          breadcrumb: [
            { name: "Home", link: "/", icon: "home" },
            { name: "Configurações do Sistema" },
          ],
        },
      },
      {
        path: "admin-grupoacesso",
        name: "admin-grupoacesso",
        component: AccessGroupPage,
        meta: {
          breadcrumb: [
            { name: "Home", link: "/", icon: "home" },
            { name: "Grupo de Acesso" },
          ],
        },
        children: [
          {
            path: "",
            name: "table-admin-grupoacesso",
            component: () =>
              import(
                "@/pages/securityAndSettings/accessGroup/tableAccessGroup.vue"
              ),
            meta: {
              breadcrumb: [
                { name: "Home", link: "/", icon: "home" },
                { name: "Grupo de Acesso" },
              ],
            },
          },
          {
            path: "edit/:id",
            name: "edit-admin-grupoacesso",
            component: () =>
              import(
                "@/pages/securityAndSettings/accessGroup/formAccessGroup.vue"
              ),
            meta: {
              breadcrumb: [
                { name: "Home", link: "/", icon: "home" },
                { name: "Grupo de Acesso" },
              ],
            },
          },
          {
            path: "create",
            name: "create-admin-grupoacesso",
            component: () =>
              import(
                "@/pages/securityAndSettings/accessGroup/formAccessGroup.vue"
              ),
            meta: {
              breadcrumb: [
                { name: "Home", link: "/", icon: "home" },
                { name: "Grupo de Acesso" },
              ],
            },
          },
        ],
      },
      {
        path: "admin-conn",
        name: "admin-conn",
        component: "",
        meta: {
          breadcrumb: [
            { name: "Home", link: "/", icon: "home" },
            { name: "Conexão Externa" },
          ],
        },
      },
      {
        path: "admin-credent",
        name: "admin-credent",
        component: () =>
          import("@/pages/securityAndSettings/credentials/credentialsPage.vue"),
        meta: {
          breadcrumb: [
            { name: "Home", link: "/", icon: "home" },
            { name: "Credênciais" },
          ],
        },
        children: [
          {
            path: "",
            name: "table-credentials",
            component: () =>
              import(
                "@/pages/securityAndSettings/credentials/tableCredentials.vue"
              ),
            meta: {
              breadcrumb: [
                { name: "Home", link: "/", icon: "home" },
                { name: "Credenciais" },
              ],
            },
          },
          {
            path: ":id",
            name: "form-cred-edit",
            component: () =>
              import(
                "@/pages/securityAndSettings/credentials/formCredentials.vue"
              ),
            meta: {
              breadcrumb: [
                { name: "Home", link: "/", icon: "home" },
                { name: "Credenciais" },
              ],
            },
          },
          {
            path: "create",
            name: "form-cred-create",
            component: () =>
              import(
                "@/pages/securityAndSettings/credentials/formCredentials.vue"
              ),
            meta: {
              breadcrumb: [
                { name: "Home", link: "/", icon: "home" },
                { name: "Credenciais" },
              ],
            },
          },
        ],
      },
      {
        path: "admin-migration",
        name: "admin-migration",
        component: "",
        meta: {
          breadcrumb: [
            { name: "Home", link: "/", icon: "home" },
            { name: "Migração de Dados" },
          ],
        },
      },
      {
        path: "fis-cndcnpjs",
        name: "admin-companies",
        component: () =>
          import("@/pages/certificateManagement/companies/companiesPage.vue"),
        meta: {
          breadcrumb: [
            { name: "Home", link: "/", icon: "home" },
            { name: "Empresas" },
          ],
        },
        children: [
          {
            path: "",
            name: "table-companies",
            component: () =>
              import(
                "@/pages/certificateManagement/companies/tableCompanies.vue"
              ),
          },
          {
            path: ":id",
            name: "edit-companies",
            component: () =>
              import(
                "@/pages/certificateManagement/companies/formCompanies.vue"
              ),
          },
          {
            path: "create",
            name: "create-companies",
            component: () =>
              import(
                "@/pages/certificateManagement/companies/formCompanies.vue"
              ),
          },
        ],
      },
      {
        path: "fis-cndmonitor",
        name: "admin-certificate",
        component: () =>
          import(
            "@/pages/certificateManagement/certificate/certificatePage.vue"
          ),
        meta: {
          breadcrumb: [
            { name: "Home", link: "/", icon: "home" },
            { name: "Monitor de Certidões" },
          ],
        },
        children: [
          {
            path: "",
            name: "table-certificate",
            component: () =>
              import(
                "@/pages/certificateManagement/certificate/tableCertificate.vue"
              ),
          },
        ],
      },
      {
        path: "cnd-panel",
        name: "cnd-panel",
        component: () => import("@/pages/certificateManagement/cndPanel.vue"),
        meta: {
          breadcrumb: [
            { name: "Home", link: "/", icon: "home" },
            { name: "Gestão de Certidões" },
          ],
        },
      },
      // Cadastros
      {
        path: "basic-status",
        name: "basic-status",
        component: () => import("@/pages/registration/status/statusPage.vue"),
        meta: {
          breadcrumb: [
            { name: "Home", link: "/", icon: "home" },
            { name: "Estados" },
          ],
        },
      },
      {
        path: "basic-uo",
        name: "basic-uo",
        component: () => import("@/pages/registration/organizationalUnit/organizationalUnitPage.vue"),
        children: [
          {
            path: "create",
            name: "create-organizational-unit",
            component: () => import("@/pages/registration/organizationalUnit/organizationalUnitCreate.vue"),
            meta: {
              breadcrumb: [
                { name: "Home", link: "/", icon: "home" },
                { name: "Unidade Organizacional", link: "/basic-uo" },
                { name: "Cadastrar Unidade Organizacional" },
              ],
            },
          },
          {
            path: "edit/:id",
            name: "edit-organizational-unit",
            component: () => import("@/pages/registration/organizationalUnit/organizationalUnitCreate.vue"),
            meta: {
              breadcrumb: [
                { name: "Home", link: "/", icon: "home" },
                { name: "Unidade Organizacional", link: "/basic-uo" },
                { name: "Cadastrar Unidade Organizacional" },
              ],
            },
          },
        ],
        meta: {
          breadcrumb: [
            { name: "Home", link: "/", icon: "home" },
            { name: "Unidade Organizacional" },
          ],
        },
      },      
      {
        path: "basic-property",
        name: "basic-property",
        component: () =>
          import("@/pages/registration/properties/propertiesPage.vue"),
        meta: {
          breadcrumb: [
            { name: "Home", link: "/", icon: "home" },
            { name: "Propriedades" },
          ],
        },
      },
      {
        path: "bus-entity",
        name: "bus-entity",
        component: () =>
          import("../pages/registration/entities/entitiesPage.vue"),
        meta: {
          breadcrumb: [
            { name: "Home", link: "/", icon: "home" },
            { name: "Entidades" },
          ],
        },
        children: [
          {
            path: "",
            name: "entity-table",
            component: () =>
              import("../pages/registration/entities/tableEntities.vue"),
          },
          {
            path: ":id",
            name: "entity-edit",
            component: () =>
              import("../pages/registration/entities/formEntities.vue"),
          },
          {
            path: "/create",
            name: "entity-create",
            component: () =>
              import("../pages/registration/entities/formEntities.vue"),
          },
        ],
      },
      {
        path: "bus-form",
        name: "bus-form",
        component: "",
        meta: {
          breadcrumb: [
            { name: "Home", link: "/", icon: "home" },
            { name: "Processo de Negócio" },
          ],
        },
      },
      {
        path: "view-admin",
        name: "view-admin",
        component: ViewsPage,
        meta: {
          breadcrumb: [
            { name: "Home", link: "/", icon: "home" },
            { name: "Visão" },
          ],
        },
        children: [
          {
            path: "",
            name: "view-table",
            component: () =>
              import("../pages/registration/views/tableView.vue"),
            meta: {
              breadcrumb: [
                { name: "Home", link: "/", icon: "home" },
                { name: "Visão" },
              ],
            },
          },
          {
            path: "layout",
            name: "view-layout",
            component: () =>
              import("../pages/registration/views/layoutView.vue"),
            meta: {
              breadcrumb: [
                { name: "Home", link: "/", icon: "home" },
                { name: "Visão" },
              ],
            },
          },
          {
            path: "layout/:id/:type",
            name: "view-grid",
            component: () => import("../pages/registration/views/gridView.vue"),
            meta: {
              breadcrumb: [
                { name: "Home", link: "/", icon: "home" },
                { name: "Visão" },
              ],
            },
          },
        ],
      },
      {
        path: "widget-admin",
        name: "widget-admin",
        component: () =>
          import("../pages/registration/widgets/widgetsPage.vue"),
        meta: {
          breadcrumb: [
            { name: "Home", link: "/", icon: "home" },
            { name: "Widgets" },
          ],
        },
        children: [
          {
            path: "",
            name: "table",
            component: () =>
              import("../pages/registration/widgets/tableWidget.vue"),
            meta: {
              breadcrumb: [
                { name: "Home", link: "/", icon: "home" },
                { name: "Widgets" },
              ],
            },
          },
          {
            path: "card",
            name: "widget-card",
            component: () =>
              import("../pages/registration/widgets/chooseCard.vue"),
            meta: {
              breadcrumb: [
                { name: "Home", link: "/", icon: "home" },
                { name: "Widgets" },
              ],
            },
          },
          {
            path: "create",
            name: "widget-create",
            component: () =>
              import("../pages/registration/widgets/widgetsModel.vue"),
            meta: {
              breadcrumb: [
                { name: "Home", link: "/", icon: "home" },
                { name: "Widgets", link: "/widget-admin" },
                { name: "Criar Widget" },
              ],
            },
          },
          {
            path: ":type/:id/",
            name: "grid",
            component: () =>
              import("../pages/registration/widgets/gridWidget.vue"),
            meta: {
              breadcrumb: [
                { name: "Home", link: "/", icon: "home" },
                { name: "Widgets" },
              ],
            },
            children: [
              {
                path: "",
                name: "grid-custom-main",
                component: () =>
                  import(
                    "@/components/layoutEditor2/infra/NestedComponent.vue"
                  ),
              },
            ],
          },
        ],
      },
      {
        path: "bus-categbusform",
        name: "bus-categbusform",
        component: () =>
          import("@/pages/registration/category/categoryPage.vue"),
        meta: {
          breadcrumb: [
            { name: "Home", link: "/", icon: "home" },
            { name: "Categorias" },
          ],
        },
        children: [
          {
            path: "",
            name: "category-table",
            component: () =>
              import("@/pages/registration/category/tableCategory.vue"),
          },
          {
            path: ":id",
            name: "category-edit",
            component: () =>
              import("@/pages/registration/category/formCategory.vue"),
          },
          {
            path: "/create",
            name: "category-create",
            component: () =>
              import("@/pages/registration/category/formCategory.vue"),
          },
        ],
      },
      {
        path: "view-pages",
        name: "view-pages",
        component: "",
        meta: {
          breadcrumb: [
            { name: "Home", link: "/", icon: "home" },
            { name: "Páginas" },
          ],
        },
      },
      {
        path: "bpm-diagram",
        name: "bpm-diagram",
        component: "",
        meta: {
          breadcrumb: [
            { name: "Home", link: "/", icon: "home" },
            { name: "Diagrama BPM" },
          ],
        },
      },
      {
        path: "ppmproject-projecttype",
        name: "ppmproject-projecttype",
        component: "",
        meta: {
          breadcrumb: [
            { name: "Home", link: "/", icon: "home" },
            { name: "Tipo de Projeto" },
          ],
        },
      },
      {
        path: "ppmproject-tasktype",
        name: "ppmproject-tasktype",
        component: "",
        meta: {
          breadcrumb: [
            { name: "Home", link: "/", icon: "home" },
            { name: "Tipo de Tarefa" },
          ],
        },
      },
      {
        path: "ppmproject-project",
        name: "ppmproject-project",
        component: "",
        meta: {
          breadcrumb: [
            { name: "Home", link: "/", icon: "home" },
            { name: "Projetos" },
          ],
        },
      },
      {
        path: "ppmproject-tasks",
        name: "ppmproject-tasks",
        component: "",
        meta: {
          breadcrumb: [
            { name: "Home", link: "/", icon: "home" },
            { name: "Tarefas" },
          ],
        },
      },
      {
        path: "ppmproject-msprojectmpp",
        name: "ppmproject-msprojectmpp",
        component: "",
        meta: {
          breadcrumb: [
            { name: "Home", link: "/", icon: "home" },
            { name: "MS Project MPP" },
          ],
        },
      },
      {
        path: "ppmproject-config",
        name: "ppmproject-config",
        component: "",
        meta: {
          breadcrumb: [
            { name: "Home", link: "/", icon: "home" },
            { name: "Configurações" },
          ],
        },
      },
      {
        path: "ppmtime-calendar",
        name: "ppmtime-calendar",
        component: "",
        meta: {
          breadcrumb: [
            { name: "Home", link: "/", icon: "home" },
            { name: "Calendário" },
          ],
        },
      },
      {
        path: "ppmtime-schedule",
        name: "ppmtime-schedule",
        component: "",
        meta: {
          breadcrumb: [
            { name: "Home", link: "/", icon: "home" },
            { name: "Cronôgrama" },
          ],
        },
      },
      {
        path: "ppmtime-gantt",
        name: "ppmtime-gantt",
        component: "",
        meta: {
          breadcrumb: [
            { name: "Home", link: "/", icon: "home" },
            { name: "Diagrama de Gantt" },
          ],
        },
      },
      {
        path: "ppmresource-role",
        name: "ppmresource-role",
        component: "",
        meta: {
          breadcrumb: [
            { name: "Home", link: "/", icon: "home" },
            { name: "Papel" },
          ],
        },
      },
      {
        path: "ppmresource-resourcecategory",
        name: "ppmresource-resourcecategory",
        component: "",
        meta: {
          breadcrumb: [
            { name: "Home", link: "/", icon: "home" },
            { name: "Categ. de Recursos" },
          ],
        },
      },
      {
        path: "ppmresource-resource",
        name: "ppmresource-resource",
        component: "",
        meta: {
          breadcrumb: [
            { name: "Home", link: "/", icon: "home" },
            { name: "Recursos" },
          ],
        },
      },
      {
        path: "rpa-bots",
        name: "rpa-bots",
        component: "",
        meta: {
          breadcrumb: [
            { name: "Home", link: "/", icon: "home" },
            { name: "Criação de Bots" },
          ],
        },
      },
      {
        path: "rpa-botspub",
        name: "rpa-botspub",
        component: "",
        meta: {
          breadcrumb: [
            { name: "Home", link: "/", icon: "home" },
            { name: "Bots Publicados" },
          ],
        },
      },
      {
        path: "rpa-botsqueue",
        name: "rpa-botsqueue",
        component: "",
        meta: {
          breadcrumb: [
            { name: "Home", link: "/", icon: "home" },
            { name: "Painel de Controle" },
          ],
        },
      },
      {
        path: "rpa-devqueue",
        name: "rpa-devqueue",
        component: "",
        meta: {
          breadcrumb: [
            { name: "Home", link: "/", icon: "home" },
            { name: "Logs de Criação" },
          ],
        },
      },
      {
        path: "rpa-devices",
        name: "rpa-devices",
        component: "",
        meta: {
          breadcrumb: [
            { name: "Home", link: "/", icon: "home" },
            { name: "Dispositivos" },
          ],
        },
      },

      {
        path: "int-monitor",
        name: "int-monitor",
        component: () => import("@/pages/monitor/monitorPage.vue"),
        breadcrumb: [
          { name: "Home", link: "/", icon: "home" },
          { name: "Monitor" },
        ],
        children: [
          {
            path: "",
            name: "int-monitor-empty",
            component: () => import("@/pages/monitor/EmptyStateMonitor.vue"),
            breadcrumb: [
              { name: "Home", link: "/", icon: "home" },
              { name: "Monitor" },
            ],
          },
          {
            path: ":id",
            name: "int-monitor-table",
            component: () => import("@/pages/monitor/tableMonitor.vue"),
            breadcrumb: [
              { name: "Home", link: "/", icon: "home" },
              { name: "Monitor" },
            ],
          },
        ],
      },

      {
        path: "fis-danfeemp",
        name: "fis-danfeemp",
        component: "",
        meta: {
          breadcrumb: [
            { name: "Home", link: "/", icon: "home" },
            { name: "DANFE por Empresa" },
          ],
        },
      },
      {
        path: "fis-danfensu",
        name: "fis-danfensu",
        component: "",
        meta: {
          breadcrumb: [
            { name: "Home", link: "/", icon: "home" },
            { name: "DANFEs por NSU" },
          ],
        },
      },
      {
        path: "/flow",
        name: "flow",
        component: () => import("@/pages/vueFlow/VueFlow.vue"),
        beforeEnter: guardianRoute,
      },
      {
        path: "/bpmflow",
        name: "bpmflow",
        component: () => import("@/pages/vueFlow/BPMDesigner.vue"),
        beforeEnter: guardianRoute,
      },
      {
        path: "/bfrender",
        name: "bfrender",
        component: () => import("@/pages/formRenderer/BFRenderer.vue"),
        beforeEnter: guardianRoute,
      },
      {
        path: "/form-builder",
        name: "form-builder",
        component: () => import("@/pages/formBuilder/formComponent.vue"),
        beforeEnter: guardianRoute,
        children: [
          {
            path: "create",
            name: "form-builder-create",
            component: () =>
              import("@/pages/formBuilder/infra/NestedComponent.vue"),
          },
          {
            path: ":id",
            name: "form-builder-edit",
            component: () =>
              import("@/pages/formBuilder/infra/NestedComponent.vue"),
          },
        ],
      },
      {
        path: "/c/dv3/checklist",
        name: "dv3-checklist",
        component: () => import("@/pages/dv3/checklistPage.vue"),
        beforeEnter: guardianRoute,
        children: [
          {
            path: "",
            name: "checklist-table",
            component: () => import("@/pages/dv3/checklistTable.vue"),
          },
          {
            path: "create",
            name: "checklist-create",
            component: () => import("@/pages/dv3/checklistForm.vue"),
          },
          {
            path: ":id",
            name: "checklist-edit",
            component: () => import("@/pages/dv3/checklistForm.vue"),
          },
        ],
      },
      {
        path: "/c/euro/search",
        name: "euro-search",
        component: () => import("@/pages/eurofarma/search/searchPage.vue"),
        beforeEnter: guardianRoute,
        children: [],
      },
      {
        path: "c/euro/esforco",
        name: "euro-esforco",
        component: () => import("@/pages/eurofarma/esfPromocional.vue"),
        meta: {
          breadcrumb: [
            { name: "Home", link: "/", icon: "home" },
            { name: "Esforço" },
          ],
        },
        children: [
          {
            path: "",
            name: "euro-esforco",
            component: () => import("@/pages/eurofarma/esfTable.vue"),
          },
        ],
      },
      {
        path: "c/euro/grade",
        name: "euro-grade",
        component: () => import("@/pages/eurofarma/gradePromocional.vue"),
        meta: {
          breadcrumb: [
            { name: "Home", link: "/", icon: "home" },
            { name: "Grade" },
          ],
        },
        children: [
          {
            path: "",
            name: "euro-grade",
            component: () => import("@/pages/eurofarma/gradeTable.vue"),
          },
        ],
      },
      {
        path: "c/euro/paramsgrade",
        name: "euro-paramsgrade",
        component: () =>
          import("@/pages/eurofarma/gradeParams/gradeParams.vue"),
        meta: {
          breadcrumb: [
            { name: "Home", link: "/", icon: "home" },
            { name: "Parâmetros" },
          ],
        },
        children: [
          {
            path: "",
            name: "euro-paramsgrade",
            component: () =>
              import("@/pages/eurofarma/gradeParams/gradeParamsTable.vue"),
          },
        ],
      },
      {
        path: "c/euro/impacto",
        name: "euro-impacto",
        component: () =>
          import("@/pages/eurofarma/euroImpacto/euroImpacto.vue"),
        meta: {
          breadcrumb: [
            { name: "Home", link: "/", icon: "home" },
            { name: "Impacto" },
          ],
        },
        children: [
          {
            path: "",
            name: "euro-impacto",
            component: () =>
              import("@/pages/eurofarma/euroImpacto/euroImpactoTables.vue"),
          },
        ],
      },
      {
        path: "c/euro/valorizacao",
        name: "euro-valorizacao",
        component: () =>
          import("@/pages/eurofarma/euroValorizacao/euroValorizacao.vue"),
        meta: {
          breadcrumb: [
            { name: "Home", link: "/", icon: "home" },
            { name: "Valorização" },
          ],
        },
        children: [
          {
            path: "",
            name: "euro-valorizacao",
            component: () =>
              import("@/pages/eurofarma/euroValorizacao/euroValTables.vue"),
          },
        ],
      },
      {
        path: "/form/:id",
        name: "form-dynamic",
        component: () => import("@/pages/formBuilder/formPage/formPage.vue"),
        beforeEnter: guardianRoute,
        children: [],
      },
    ],
    beforeEnter: guardianRoute,
  },
  {
    path: "/exemple",
    name: "exemple",
    component: Home,
    children: [
      {
        path: "form-view",
        name: "form_view",
        component: () => import("../pages/formViewExemple.vue"),
      },
    ],
    beforeEnter: guardianRoute,
  },
  {
    path: "/views",
    name: "views",
    component: Home,
    children: [{ path: "dash/:id", name: "views", component: DashPage }],
    beforeEnter: guardianRoute,
  },
  {
    path: "/testes",
    name: "testes",
    component: TestesLuanPage,
    beforeEnter: guardianRoute,
  },

  // {
  //   path: "/testes-kanban",
  //   name: "testes-kanban",
  //   component: () => import('../pages/Testes/testes-kanban/IndexPage.vue'),
  //   children:[
  //     {path:'criar',name:'criar',component: () => import('../pages/Testes/testes-kanban/CriarKanban.vue')}
  //   ],
  //   beforeEnter: guardianRoute,
  // },
  {
    path: "/agile",
    name: "agile",
    component: Home,
    meta: {
      breadcrumb: [
        { name: "Home", link: "/", icon: "home" },
        { name: "Boards" },
      ],
    },
    children: [
      {
        path: "boards",
        name: "boards",
        component: () => import("@/pages/products/boards/indexBoardPage.vue"),
        children: [
          {
            path: ":boardId/sprint/:sprintId",
            name: "board",
            component: () =>
              import("@/pages/products/boards/tasksBoardPage.vue"),
            // children: [
            //   {
            //     path: "sprint/:sprintId",
            //     name: "sprint",
            //     component: () => import("@/pages/home/homePage.vue"),
            //   },
            // ],
          },
        ],
      },
      {
        path: "projects",
        name: "projects",
        component: () => import("@/pages/products/projects/indexPage.vue"),

        children: [
          {
            path: "",
            name: "home-project",
            component: () =>
              import("@/pages/products/projects/projectListPage.vue"),
            meta: {
              breadcrumb: [
                { name: "Home", link: "/", icon: "home" },
                { name: "Projetos" },
              ],
            },
          },
          {
            path: ":companyId",
            component: () => import("@/pages/products/projects/listsPage.vue"),
            children: [
              {
                path: ":projectId/activities",
                component: () =>
                  import("@/pages/products/projects/listsPage.vue"),
                meta: {
                  breadcrumb: [
                    { name: "Home", link: "/", icon: "home" },
                    {
                      name: "Projetos",
                      link: "/agile/projects",
                      icon: "fal fa-list",
                    },
                    { name: "Atividades" },
                  ],
                },
                children: [
                  {
                    path: "",
                    name: "activities",
                    component: () =>
                      import("@/pages/products/projects/activitiesPage.vue"),
                    meta: {
                      breadcrumb: [
                        { name: "Home", link: "/", icon: "home" },
                        {
                          name: "Projetos",
                          link: "/agile/projects",
                          icon: "folder",
                        },
                        { name: "Atividades" },
                      ],
                    },
                  },
                  {
                    path: ":activityId/tasks",
                    name: "tasks",
                    component: () =>
                      import("@/pages/products/projects/tasksPage.vue"),
                    meta: {
                      breadcrumb: [
                        { name: "Home", link: "/", icon: "home" },
                        {
                          name: "Projetos",
                          link: "/agile/projects",
                          icon: "folder",
                        },
                        { name: "Tarefas" },
                      ],
                    },
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        path: "kanban",
        name: "kanban",
        component: ProjectsListKanban,
        meta: {
          breadcrumb: [
            { name: "Home", link: "/", icon: "home" },
            { name: "Projetos" },
          ],
        },
      },
      {
        path: "kanban/:project",
        name: "projects-kanban",
        // component: KanbanPage,
        meta: {
          breadcrumb: [
            { name: "Home", link: "/", icon: "home" },
            { name: "Projetos", link: "/agile/kanban", icon: "fal fa-list" },
            { name: "Kanban" },
          ],
        },
      },

      {
        path: "email",
        name: "email",
        component: EmailPage,
        meta: {
          breadcrumb: [
            { name: "Home", link: "/", icon: "home" },
            { name: "Email" },
          ],
        },
      },
    ],
    beforeEnter: guardianRoute,
  },
];

const router = createRouter({
  history: createWebHistory(),
  //history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  if (useLoginStore().authenticated === false && !to.path.startsWith("/login")) {
    next("/login");
  } else {
    next();
  }
  const gridStore = useApi();
  if (!to.path.startsWith("/views")) {
    gridStore.notify();
  }
});
export default router;
