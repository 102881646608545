<template>
  <div>
    <div
      v-if="loading"
      class="w-full h-[100vh] flex items-center justify-center"
    >
      <q-inner-loading
        :showing="true"
        color="secondary"
        :label="$t('login.welcomeLoading')"
        label-class="text-secondary"
        label-style="font-size: 1.1em"
      />
    </div>
    <div v-if="!loading">
      <LoginLayout1
        v-if="selectedLayout === 1"
        :config="layoutConfig"
        :language="language"
        @selectLanguage="selectLanguage($event)"
        :logo="logo"
      />
      <LoginLayout2
        v-if="selectedLayout === 2"
        :config="layoutConfig"
        :language="language"
        @selectLanguage="selectLanguage($event)"
        :logo="logo"
      />
    </div>
  </div>
</template>

<script setup>
import { computed, onMounted } from "vue";
import { useHomeConfig } from "@/store/homeConfigStore.js";
import LoginLayout1 from "@/components/login/layouts/LoginLayout1.vue";
import LoginLayout2 from "@/components/login/layouts/LoginLayout2.vue";
import i18next from "i18next";
import logoImage from "@/assets/images/logoPurple.png";

const store = useHomeConfig();
const layoutConfig = computed(() => store.loginLayout);
const validLanguages = ["pt", "es", "en"];
const language = computed(() => {
  return validLanguages.includes(store.languageSelected)
    ? store.languageSelected
    : "pt";
});
const logo = computed(() =>
  store.imageLogin === "" ? logoImage : store.imageLogin
);
const selectedLayout = computed(() => store.loginLayout.selectedLayout);
const loading = computed(() => store.homeLoading);

function selectLanguage(language) {
  console.log(language);
  i18next.changeLanguage(language);
  store.languageSelected = language;
}
onMounted(async () => {
  await store.getLoginHome();
})

// const homeConfig = computed(() => store.config.configLogin);
// const loading = computed(() => store.homeLoading);

// function decodeHTMLEntities(text) {
//   var textarea = document.createElement("textarea");
//   textarea.innerHTML = text;
//   return textarea.value;
// }
</script>
