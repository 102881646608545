<template>
  <header
    class="w-full h-[55px] flex justify-between items-center px-4 md:px-7 border-b-[1px] border-grey200"
  >
    <div class="flex items-end">
      <div class="md:hidden">
        <q-img
          :src="homeConfig.imageIconMenu"
          spinner-color="grey-5"
          spinner-size="2rem"
          fit="contain"
          style="width: 1.4rem; height: 1.5rem"
        />
      </div>
      <q-separator vertical spaced class="md:hidden" />
      <BreadcrumbComponent />
      <PopupLoginComponent v-if="loginStore.showPopupLogin" />
    </div>
    <div class="flex items-center">
      <div class="block md:hidden">
        <q-btn
          flat
          color="secondary"
          icon="fas fa-bars"
          @click="menuStore.showMenuMobile = true"
          padding="5px 0px"
        />
      </div>
      <div class="hidden md:flex">
        <div class="flex items-center space-x-1">
          <q-btn flat round>
            <q-avatar
              size="40px"
              :color="currentUserPhotoUrl ? 'white' : 'secondary'"
              text-color="white"
            >
              <img v-if="currentUserPhotoUrl" :src="currentUserPhotoUrl" />
              <span v-else>{{ getInitialLetters(username) }}</span>
            </q-avatar>

            <q-menu
              :offset="[0, 15]"
              class="rounded-[10px]"
              :style="`background-color: ${homeConfig.menuColor};`"
            >
              <q-list style="min-width: 300px">
                <q-item
                  class="flex flex-col justify-center text-white items-center"
                >
                  <q-item-label class="text-sm p-3">{{
                    hostname.split(".")[0]
                  }}</q-item-label>
                  <q-avatar size="65px" color="secondary" text-color="white">
                    <img
                      v-if="currentUserPhotoUrl"
                      :src="currentUserPhotoUrl"
                    />
                    <span v-else>{{ getInitialLetters(username) }}</span>
                  </q-avatar>
                  <q-item-label
                    class="capitalize text-white py-3 text-[1.25rem]"
                    >{{
                      $t("header.greetings", { name: username })
                    }}</q-item-label
                  >
                </q-item>
                <div class="py-1 px-3">
                  <q-item
                    auto-close
                    clickable
                    v-ripple
                    class="rounded-full bg-white/[.1]"
                    style="padding: 8px 16px 8px 26px"
                  >
                    <router-link
                      :to="`/profile/${userId}`"
                      class="flex items-center"
                    >
                      <q-icon
                        color="white"
                        name="o_person"
                        size="24px"
                        class="mr-3"
                      />
                      <q-item-section class="font-medium text-white"
                        >{{ $t('header.profile') }}</q-item-section
                      >
                    </router-link>
                  </q-item>
                </div>
                <div class="py-1 px-3">
                  <q-item
                    auto-close
                    clickable
                    v-ripple
                    class="flex rounded-full bg-white/[.1]"
                    style="padding: 8px 16px 8px 26px"
                  >
                    <div class="w-100 flex items-center justify-between">
                      <div class="flex items-center">
                        <q-icon
                          color="white"
                          name="translate"
                          size="24px"
                          class="mr-3"
                        />
                        <q-item-section class="font-medium text-white"
                          >{{ $t('header.languages') }}</q-item-section
                        >
                      </div>
                      <div class="flex items-center">
                        <p class="text-grey200 text-[14px] mr-2">{{ languages[homeConfig.languageSelected]?.label }}</p>
                        <q-img width="32px" height="32px" :src="languages[homeConfig.languageSelected]?.flag"></q-img>
                      </div>
                    </div>
                    <q-menu v-model="showLanguageMenu" class="rounded-[10px]">
                      <q-list style="min-width: 270px">
                        <q-item
                          clickable
                          class="flex items-center"
                          v-for="language of languages"
                          :key="language.key"
                          @click="selectLanguage(language.key)"
                        >
                          <q-item-section>{{ language.label }}</q-item-section>
                          <q-img
                            width="32px"
                            height="32px"
                            :src="language.flag"
                          ></q-img>
                        </q-item>
                      </q-list>
                    </q-menu>
                  </q-item>
                </div>
                <q-separator class="my-2" horizontal />
                <div class="py-1 mb-2 px-3">
                  <q-item
                    clickable
                    v-ripple
                    class="flex rounded-full items items-center bg-white/[.1]"
                    style="padding: 8px 16px 8px 26px"
                    @click="logout"
                  >
                    <q-icon
                      color="white"
                      name="logout"
                      size="24px"
                      class="mr-3"
                    />

                    <q-item-section class="font-medium text-white">{{
                      $t("header.logout")
                    }}</q-item-section>
                  </q-item>
                </div>
              </q-list>
            </q-menu>
          </q-btn>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import router from "../../routes/router";
import { useLoginStore } from "@/store/loginStore";
import { useMenuSidebarStore } from "@/store/menuStore";
import { useHomeConfig } from "@/store/homeConfigStore.js";
import { getInitialLetters } from "@/utils/useUtils";
import BreadcrumbComponent from "../../components/globalWeb/breadcrumb/breadcrumbComponent.vue";
import PopupLoginComponent from "@/components/login/popupLoginComponent.vue";
import brFlag from "@/assets/images/ptbr_flag.png";
import esFlag from "@/assets/images/es_flag.png";
import enFlag from "@/assets/images/en_flag.png";

export default {
  name: "HeaderComponent",
  components: {
    BreadcrumbComponent,
    PopupLoginComponent,
  },
  emits: ["showModal"],
  data() {
    const loginStore = useLoginStore();
    const menuStore = useMenuSidebarStore();
    const homeConfig = useHomeConfig();
    return {
      dropdownLogout: false,
      hostname: window.location.hostname,
      loginStore,
      menuStore,
      homeConfig,
      getInitialLetters,
      brFlag,
      esFlag,
      enFlag,
      showLanguageMenu: false,
      languages: {
        pt: {
          flag: brFlag,
          key: "pt",
          label: "Português",
        },
        en: {
          flag: enFlag,
          key: "en",
          label: "English",
        },
        es: {
          flag: esFlag,
          key: "es",
          label: "Español",
        },
      },
    };
  },

  mounted() {},
  methods: {
    logout() {
      localStorage.removeItem("@vueweb:accessToken");
      localStorage.removeItem("@vueweb:username");
      localStorage.removeItem("@vueweb:user");
      localStorage.removeItem("@vueweb:currentEnvironment");
      localStorage.removeItem("@vueweb:loggedUser");
      localStorage.removeItem("@vueweb:imageProfile");
      this.menuStore.itemsGeneralMenu = [];
      this.menuStore.itemsSettingsMenu = [];
      router.push("/login");
    },
    handleDropdown() {
      this.dropdownLogout = !this.dropdownLogout;
    },
    selectLanguage(language) {
      this.showLanguageMenu = false;
      this.$i18next.changeLanguage(language);
      this.homeConfig.languageSelected = language;
    },
  },

  computed: {
    userId() {
      return localStorage.getItem("@vueweb:user");
    },
    currentUserPhotoUrl() {
      return this.loginStore.profileImage ? this.loginStore.profileImage : "";
    },
    username() {
      const storedUsername = localStorage.getItem("@vueweb:loggedUser");
      return storedUsername !== null && storedUsername !== "undefined"
        ? storedUsername
        : "";
    },
  },

  props: {
    name: {
      type: String,
      default: "",
    },
    role: {
      type: String,
      default: "",
    },
    image: {
      type: String,
      default: "",
    },
  },
};
</script>
