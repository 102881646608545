import { defineStore } from "pinia";
import { apiDev } from "@/services/api";
import i18next from "i18next";

export const useMenuSidebarStore = defineStore("menuSidebar", {
  state: () => ({
    itemsMenu: [],
    status: true,
    showMenuMobile: false,
    itemsGeneralMenu: [],
    itemsSettingsMenu: [],
  }),

  actions: {
    async getMenu() {
      await apiDev
        .get("/adm/v2/home/menu2")
        .then((response) => {
          this.itemsGeneralMenu = this.substituirIcones(response.data.data.overview)
            .map((item) => ({
              ...item,
              submenu: item.submenu.sort((a, b) => {
                const titleA = a.title.startsWith('$') ? i18next.t(a.title).toUpperCase() : a.title.toUpperCase();
                const titleB = b.title.startsWith('$') ? i18next.t(b.title).toUpperCase() : b.title.toUpperCase();
                if (titleA < titleB) {
                  return -1;
                }
                if (titleA > titleB) {
                  return 1;
                }
                return 0;
              }),
              show: false,
            }))
            .sort((a, b) => {
              const titleA = a.title.startsWith('$') ? i18next.t(a.title).toUpperCase() : a.title.toUpperCase(); // ignore case
              const titleB = b.title.startsWith('$') ? i18next.t(b.title).toUpperCase() : b.title.toUpperCase(); // ignore case
              if (titleA < titleB) {
                return -1;
              }
              if (titleA > titleB) {
                return 1;
              }
              return 0; 
            });
          this.itemsSettingsMenu = this.substituirIcones(response.data.data.settings)
            .map((item) => ({
              ...item,
              submenu: item.submenu.sort((a, b) => {
                const titleA = a.title.startsWith('$') ? i18next.t(a.title).toUpperCase() : a.title.toUpperCase();
                const titleB = b.title.startsWith('$') ? i18next.t(b.title).toUpperCase() : b.title.toUpperCase();
                if (titleA < titleB) {
                  return -1;
                }
                if (titleA > titleB) {
                  return 1;
                }
                return 0;
              }),
              show: false,
            }))
            .sort((a, b) => {
              const titleA = a.title.startsWith('$') ? i18next.t(a.title).toUpperCase() : a.title.toUpperCase(); // ignore case
              const titleB = b.title.startsWith('$') ? i18next.t(b.title).toUpperCase() : b.title.toUpperCase(); // ignore case
              if (titleA < titleB) {
                return -1;
              }
              if (titleA > titleB) {
                return 1;
              }
              return 0; 
            });
        })
        .catch((error) => {
          console.log("getMenus", error);
        })
        .finally(() => {
          this.status = false;
        });
    },
    substituirIcones(arrayDeElementos) {
      return arrayDeElementos.map((item) => {
        if (item.icon.startsWith("fa ")) {
          item.icon = item.icon.replace("fa ", "fas ");
        } else if (item.icon.startsWith("fal ")) {
          item.icon = item.icon.replace("fal ", "fas ");
        }
        return item;
      });
    },
  },
});
