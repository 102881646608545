<template>
  <div
    :class="`relative w-full overflow-y-hidden px-[32px] bg-[#F9F9F9]`"
    style="height: 100%; max-width: 100%"
  >
    <SkeletonComponent v-if="!apiIsReady" />
    <div v-if="apiIsReady">
      <!-- <div v-if="data.cmps.some(cmp => cmp.type === 'body' && cmp.mode === 'single')">
      <HeaderAndBodySingleComponent :data="data.cmps"/>
    </div> -->
      <div v-if="message !== null">
        {{ message }}
      </div>
      <div v-else class="h-full w-full">
        <div class="h-auto w-full relative">
          <header
            class="w-full flex justify-between items-center flex-wrap"
            id="header_dash"
          >
            <h2 class="text-[22px] font-bold py-1 px-2 min-w-fit">
              {{ header.title }}
            </h2>
            <section
              class="w-auto flex justify-start items-center p-2 space-x-2"
            >
              <DashChildrenComponents :data="header" />
            </section>
          </header>
          <section
            v-if="haveFiltersHeader"
            id="filters_section"
            :loading="apiStore.loading"
            class="w-auto flex justify-start items-center p-[10px] border border-[#E0E0E0] bg-white"
          >
            <div class="flex flex-wrap gap-x-2 gap-y-2">
              <div class="flex items-center">
                <q-icon size="24px" name="filter_list" />
                <p class="font-bold ml-3 mr-[16px]">Filtros</p>
              </div>
              <DashChildrenComponents :data="filters" :key="filters" />
              <q-btn
                v-if="hasHiddenComponents"
                flat
                dense
                @click="showAllFilters = !showAllFilters"
                no-caps
                color="secondary"
                class="text-none"
              >
              <q-badge v-if="hiddenFiltersActive" floating rounded color="secondary">{{ hiddenFiltersActive }}</q-badge>
                {{
                  showAllFilters
                    ? "Esconder filtros..."
                    : "Ver todos os filtros..."
                }}
              </q-btn>
            </div>
          </section>
          <section
            v-if="version === 'v_grid'"
            class="w-full relative"
            :style="heightBody"
          >
            <DashGridComponent :data="body" />
          </section>
          <section v-else class="w-full relative" :style="heightBody">
            <DashChildrenComponents :data="body" />
          </section>
        </div>
      </div>
    </div>
    <confirmationDialog />
  </div>
</template>

<script>
import { useApi } from "@/store/api-gridStore";
import { useDash } from "@/store/dash/dash-api";
// import matrizColumns from "@/api-examples/matrizColumns.js";
import SkeletonComponent from "@/components/Handler/Dash/Skeleton/SkeletonComponent.vue";
// import DashParentComponent from '@/components/Handler/Dash/DashParentComponent.vue';
// import dashApiExemple from "../../../api-examples/dashApiExemple.js";
import DashChildrenComponents from "@/components/Handler/Dash/DashChildrenComponents.vue";
import DashGridComponent from "@/components/Handler/Dash/DashGridComponent.vue";
import confirmationDialog from "@/components/globalWeb/confirmationDialog.vue";
// import SelectDash from "@/components/Handler/Dash/Cmps/SelectDash.vue";
// import HeaderAndBodySingleComponent from '@/components/Handler/Dash/Single/HeaderAndBodySingleComponent.vue';

// import BodyDashComponent from '@/components/Handler/Dash/Body/BodyDashComponent.vue';
export default {
  data() {
    const store = useDash();
    const apiStore = useApi();
    return {
      store,
      apiStore,
      apiIsReady: false,
      header: {},
      filtersData: {},
      testeData: {},
      body: {},
      id: null,
      heightBody: 0,
      message: null,
      showAllFilters: false,
      // header:null,
      // body:null
    };
  },
  // async created() {
  //   await this.store.getData(this.$route.params.id);
  //   // this.data = this.store.data
  //   // this.id = parseInt(this.$route.params.id);
  //   // this.header = this.store.data.children.find((cmp) => cmp.type === "header");
  //   // this.body = this.store.data.children.find((cmp) => cmp.type === "body");
  //   this.apiIsReady = true;
  // },
  updated() {
    const header = document.getElementById("header_dash");
    const filters = document.getElementById("filters_section");
    if (header !== null) {
      let heightHeader = header.offsetHeight;
      this.store.headersHeight = {
        [this.$route.params.id]: heightHeader,
      };
      if (filters !== null) {
        let heightFilters = filters.offsetHeight;
        this.store.filtersHeight = {
          [this.$route.params.id]: heightFilters,
        };
        this.heightBody = `height:calc(100vh - ${
          heightHeader + heightFilters + 10
        }px)`;
      } else {
        this.heightBody = `height:calc(100vh - ${heightHeader + 20}px)`;
      }
    } else {
      this.heightBody = `height:calc(100vh - ${100 + 20}px)`;
    }
  },
  async mounted() {
    const dashId = this.$route.params.id

    await this.store.getData(dashId);
    
    this.apiStore.setDashId(dashId);

    if (this.store?.data?.message || !this.store.data?.children) {
      this.message = "Não há dados para serem exibidos!";
    } else {
      this.message = null;

      this.header = this.store.data.children.find(
        (child) => child.type == "header"
      );
      this.filtersData = this.store.data.children.find(
        (child) => child.type == "filters"
      );
      const data = this.store.data.children.find(
        (child) => child.type == "body"
      );

      if (this.header) {
        this.addElementsToStore(this.header.children, dashId);
      }
      if (this.filtersData) {
        this.addElementsToStore(this.filtersData.children, dashId);
      }

      this.body = data;
    }

    this.apiIsReady = true;
  },
  computed: {
    version() {
      return this.store.data.version;
    },
    filters() {
      let visibleComponents = { ...this.filtersData };
      if (!this.showAllFilters && this.filtersData?.children?.length > 5) {
        visibleComponents.children = visibleComponents.children.slice(
          0,
          this.numberOfFilters
        );
        return visibleComponents;
      } else {
        return this.filtersData;
      }
    },

    hasHiddenComponents() {
      return this.filtersData?.children?.length > 5;
    },

    hiddenFiltersActive() {
      if (!this.filters || !this.filters.children || !this.store.elements[this.$route.params.id]) {
        return 0;
      }

      const visibleFilterIds = this.filters.children.map(child => child.id);
      const hiddenFilters = this.store.elements[this.$route.params.id].filter(
        element => !visibleFilterIds.includes(element.id)
      );

      return hiddenFilters.filter(filter => filter.model).length;
    },

    haveFiltersHeader() {
      return this.filtersData && Object.keys(this.filtersData).length > 0 && this.filtersData?.children?.length > 0;
    },

    numberOfFilters() {
      const width = window.innerWidth;
      if (width < 1190) {
        return 2;
      } else if (width >= 1190 && width < 1390) {
        return 3;
      } else if (width >= 1390 && width < 1570) {
        return 4;
      } else {
        return 5;
      }
    },
  },
  components: {
    SkeletonComponent,
    DashChildrenComponents,
    DashGridComponent,
    confirmationDialog,
  },

  methods: {
    addElementsToStore(elements, dashId) {
      if (!this.store.elements[dashId]) {
        this.store.elements[dashId] = [];
      }
      if (!this.store.requiredDashElements[dashId]) {
        this.store.requiredDashElements[dashId] = [];
      }
      elements.forEach((element) => {
        if (!this.store.elements[dashId].some((el) => el.id === element.id)) {
          this.store.elements[dashId].push(element);
        }
        if (element.required) {
          this.store.requiredDashElements[dashId].push(element);
        }
      });
    },
  },
};
</script>
