<template>
  <div class="flex w-full h-[100vh]">
    <div>
      <q-skeleton width="250px" class="h-[100vh]" />
    </div>
    <div>
      <q-skeleton class="ml-[20px] mt-[20px]" width="250px" height="30px" />
      <q-skeleton class="absolute top-3 right-5" type="circle" size="50px" />
      <q-inner-loading v-if="!loginStore.showPopupLogin"
        :showing="true"
      />
    </div>
  </div>
</template>

<script setup>
import { useLoginStore } from "@/store/loginStore";
const loginStore = useLoginStore();

</script>

<style>
</style>