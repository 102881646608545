import { defineStore } from "pinia";
import { Buffer } from "buffer";
import Notify from 'quasar/src/plugins/Notify.js';;
import { apiDev } from "@/services/api";
import { useStorage } from "@vueuse/core";
import { useDash } from "./dash/dash-api";
import { TableData } from "@/components/Handler/Dash/Cmps/widget/grid/tableDataExample";

export const useApi = defineStore("api", {
  state() {
    return {
      widgetId: null,
      data: [],
      columns: [],
      listData: [],
      currentId: null,
      singleData: null,
      isFiltered: {},
      currentPage: null,
      autoload: false,
      // currentStarRow: 0,
      //alteração Luan
      pagination: {
        sortBy: "title",
        descending: true,
        page: 1,
        rowsPerPage: 50,
        rowsNumber: 0,
      },
      //alteração Luan

      dashId: null,
      filters: useStorage("v1@eztools.filters.dash", {}),

      load: false,
      isReload: {},
      filtersRequired: {},
      loading: false,
      selecteds: [],
      notify: () => void 0,
    };
  },
  getters: {
    orderAndFilters: (state) => {
      return {
        filters: state.filters[state.dashId],
        order: {
          by: state.pagination.sortBy,
          direction: state.pagination.descending ? "DESC" : "ASC",
        },
      };
    },
  },
  actions: {
    async getDataByPageNumber(id = null, autoload = null) {
      const dashStore = useDash();

      this.data = [];

      if (id !== null) this.currentId = id;
      if (autoload !== null) this.autoload = autoload;

      let queriesArray = [
        `page=${this.pagination.page}`,
        `perPage=${this.pagination.rowsPerPage}`,
      ];


      const allRequiredItemsFilled = dashStore.requiredDashElements[this.dashId].every(
        (requiredItem) => {
          const element = dashStore.elements[this.dashId].find(el => el.id === requiredItem.id);
          return element && element.model !== undefined && element.model !== null;
        }
      );

      if (
        this.orderAndFilters.order &&
        Object.keys(this.orderAndFilters.order).length > 0
      ) {
        let orderToString = JSON.stringify(this.orderAndFilters.order);
        // console.log("FILTERS TO STRING", orderToString);
        let orderBase64 = Buffer.from(orderToString).toString("base64");
        queriesArray.push(`order=${orderBase64}`);
      }

      if (
        this.filters[this.dashId] &&
        Object.keys(this.filters[this.dashId]).length > 0
      ) {
        let filtersToString = JSON.stringify(this.filters[this.dashId]);
        console.log('FILTRES', filtersToString);
        let filtersBase64 = Buffer.from(filtersToString).toString("base64");
        queriesArray.push(`filters=${filtersBase64}`);
      }
      const queries = queriesArray.join("&");

      // ********* PARTE DO CÓDIGO APARENAS PARA TESTE
      if (id === "table_test") {
        this.columns = TableData.cols;
        this.data = TableData.rows;
        console.log("COLS", this.columns);
        return;
      }
      // ********* PARTE DO CÓDIGO APARENAS PARA TESTE

      if (
        this.autoload && (!this.filtersRequired[this.dashId] || this.filtersRequired[this.dashId].length === 0) && allRequiredItemsFilled ||
        (!this.autoload &&
          this.isFiltered[this.dashId] &&
          this.isReload[this.dashId]) || (!this.autoload && dashStore.requiredDashElements[this.dashId].length > 0 && allRequiredItemsFilled)
      ) {
        this.loading = true;
        await apiDev
          .get(
            `/handler/v2/dash/load/data/${this.currentId}/${this.dashId}?${queries}`
          )
          .then((res) => {
            this.data = res.data.data.rows;
            this.originalData = res;
            this.pagination["page"] = res.data.data.page;
            this.pagination["rowsPerPage"] = res.data.data.perPage;
            this.pagination["rowsNumber"] = res.data.data.totalRows;
          })
          .catch((error) => {
            console.log(error);
            // this.originalData = res;
          })
          .finally(() => {
            this.loading = false;
            this.isReload[this.dashId] = false;
          });
      } else this.showNotify();
    },

    setPagination(pagination) {
      this.pagination = {
        sortBy: pagination.sortBy,
        descending: pagination.descending,
        page: pagination.page,
        rowsPerPage: pagination.rowsPerPage,
        rowsNumber: pagination.rowsNumber,
      };
      this.orderAndFilters["order"] = {
        by: pagination.sortBy,
        direction: pagination.descending ? "DESC" : "ASC",
      };
    },

    setDashId(id) {
      this.dashId = id;
    },

    clearData() {
      this.data = [];
    },

    setIsFiltered(arg) {
      // console.log(arg)
      this.isFiltered[this.dashId] = arg;
    },

    async getFilter() {
      this.getDataByPageNumber(
        this.currentId,
        this.pagination.page,
        this.pagination.rowsPerPage,
        this.autoload
      );
    },

    async setFilter(filter, filterInRefresh) {
      // console.log("SET FILTER", filter, filterInRefresh);
      this.loading = true;
      this.filters[this.dashId] = filter;
      if (!filterInRefresh) await this.getFilter();
      this.loading = false;
    },

    removeFilterRequired(filter) {
      let index = this.filtersRequired[this.dashId].indexOf(filter);
      this.filtersRequired[this.dashId].splice(index, 1);
      this.showNotify();
    },

    setFiltersRequired(filter) {
      if (this.filtersRequired[this.dashId]) {
        if (!this.filtersRequired[this.dashId].some((item) => item === filter))
          this.filtersRequired[this.dashId].push(filter);
      } else this.filtersRequired[this.dashId] = [filter];
    },

    async setMultiFilters(filters, id) {
      this.loading = true;
      if (filters.length < 1) {
        this.data = this.originalData.data;
        this.loading = false;
        return;
      }
      this.filters[this.dashId] = {};
      filters.forEach(async (f) => await this.getFilter(f.value));
      this.loading = false;
      this.currentId = id;
    },

    getMessageToNotify() {
      const dashStore = useDash();

      const unfilledRequiredItems = dashStore.requiredDashElements[this.dashId].filter(
        (requiredItem) => {
          const element = dashStore.elements[this.dashId].find(el => el.id === requiredItem.id);
          return !element || element.model === undefined || element.model === null;
        }
      );

      console.log('unfilledRequiredItems', unfilledRequiredItems)

      let message = "";
      if (
        this.filtersRequired[this.dashId] &&
        this.filtersRequired[this.dashId].length > 0
      ) {
        if (
          this.filtersRequired[this.dashId] &&
          this.filtersRequired[this.dashId].length > 1
        ) {
          message = "<p>Preencha os filtros: ";
          this.filtersRequired[this.dashId].forEach((label, index, arr) => {
            if (index === arr.length - 1)
              message =
                message +
                '<span class="bg-primary px-2">' +
                label +
                "</span>" +
                " ";
            else if (index === arr.length - 2)
              message =
                message +
                '<span class="bg-primary px-2">' +
                label +
                "</span>" +
                " e ";
            else
              message =
                message +
                '<span class="bg-primary px-2">' +
                label +
                "</span>" +
                " , ";
          });
        } else if (
          this.filtersRequired[this.dashId] &&
          this.filtersRequired[this.dashId].length == 1
        ) {
          message = "<p>Preencha o filtro ";
          message =
            message +
            '<span class="bg-primary px-2">' +
            this.filtersRequired[this.dashId][0] +
            "</span>" +
            " ";
        }
      } else if (
        unfilledRequiredItems &&
        unfilledRequiredItems.length > 0
      ) {
        if (
          unfilledRequiredItems &&
          unfilledRequiredItems.length > 1
        ) {
          message = "<p>Preencha os filtros: ";
          unfilledRequiredItems.forEach((element, index, arr) => {
            if (index === arr.length - 1)
              message =
                message +
                '<span class="bg-primary px-2">' +
                element.placeholder +
                "</span>" +
                " ";
            else if (index === arr.length - 2)
              message =
                message +
                '<span class="bg-primary px-2">' +
                element.placeholder +
                "</span>" +
                " e ";
            else
              message =
                message +
                '<span class="bg-primary px-2">' +
                element.placeholder +
                "</span>" +
                " , ";
          });
        } else if (
          unfilledRequiredItems &&
          unfilledRequiredItems.length == 1
        ) {
          message = "<p>Preencha o filtro ";
          message =
            message +
            '<span class="bg-primary px-2">' +
            unfilledRequiredItems[0].placeholder +
            "</span>" +
            " ";
        }
      } else {
        message = 'Clique no botão reload  <i class="fas fa-sync"></i> ';
      }
      message = message + " para exibir os dados!</p>";
      return message;
    },
    showNotify() {
      this.notify = Notify.create({
        html: true,
        message: this.getMessageToNotify(),
        position: "center",
        color: "grey-10",
        icon: "warning",
        timeout: 7000,
        progress: true,
      });
    },
  },
});
