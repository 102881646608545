export const GlobalsPT = {
    save:'Salvar',
    cancel:'Cancelar',
    search: 'Pesquisar',
    overview: "Visão Geral",
    settings: "Configurações",
    results: "Resultados",
  };
  
  export const GlobalsEN = {
    save:'Ahorrar',
    cancel:'Cancelar',
    search: 'Search',
    overview: "Overview",
    settings: "Settings",
    results: "Results",
  };
  
  export const GlobalsES = {
    save:'Salvar',
    cancel:'Cancelar',
    search: 'Buscar',
    overview: "Descripción general",
    settings: "Configuraciones",
    results: "Resultados",
  };
  