<template>
  <HeaderComponent v-if="showHeader" />

  <router-view :key="$route.path"></router-view>
</template>

<script setup>
import HeaderComponent from "@/components/securityAndSettings/accessGroup/headerComponent.vue";
import { useRoute } from "vue-router";

const route = useRoute();

const showHeader = !['/create', '/edit'].includes(route.path);
</script>
