<template>
  <header
    v-if="isTable"
    :class="`px-[2rem] py-2 w-full bg-white h-[120px]`"
  >
    <div v-if="isTable" class="flex items-center justify-between mb-2">
      <h2 class="text-xl font-semibold text-grey900">Grupo de Acesso</h2>
      <div class="flex space-x-2 ml-auto">
        <q-btn
          unelevated
          dense
          flat
          icon="refresh"
          label="Atualizar"
          color="accent"
          @click="refreshTable"
        ></q-btn>
        <q-btn
          flat
          dense
          unelevated
          color="primary"
          icon="add"
          label="grupo"
          @click="goToNewGroupAccess"
        ></q-btn>
      </div>
    </div>
    <div v-if="isTable" class="flex items-center justify-between mt-4">
      <div class="flex items-center space-x-2 w-2/3">
        <InputQuasarComponent
          v-model="textSearch"
          label="Pesquise uma palavra chave."
          type="text"
          color="light-blue"
          icon="search"
          style="width: 50%"
          class="m-0"
        />
        
        <!-- Filtro de Ativo/Inativo -->
        <q-select
          outlined
          v-model="selectActive"
          :options="actives"
          map-options
          class="filters-single-select mt-0"
          input-class="font-bold"
          style="font-size: 16px; width: 30%"
          @input="handleSelectChange"
        />
      </div>
    </div>
  </header>
</template>

<script setup>
import { ref, computed, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useAccessGroup } from "@/store/securityAndSettings/accessGroupStore.js";
import InputQuasarComponent from "../../../../../global/componentsVue/inputComponent/inputQuasarComponent.vue";

const route = useRoute();
const router = useRouter();
const store = useAccessGroup();

const actives = ref([
  { label: "Todos", value: "" },
  { label: "Apenas Ativos", value: "Y" },
  { label: "Apenas Inativos", value: "N" },
]);

const textSearch = ref(store.textSearch);
const selectActive = ref(store.selectActive || "");


const isTable = computed(() => route.name === "table-admin-grupoacesso");
// const isCreate = computed(() => store.isCreate);


const goToNewGroupAccess = () => {
  router.push("/admin-grupoacesso/create");
};


const refreshTable = () => {
  store.getAllGroups();
};


watch(
  textSearch,
  (newValue) => {
    store.textSearch = newValue;
    store.getAllGroups();
  }
);

watch(
  selectActive,
  (newValue) => {
    store.selectActive = newValue;
    store.getAllGroups();
  }
);

const handleSelectChange = (value) => {
  selectActive.value = value;
};
</script>
